import React from 'react';
import PageTemplate from '../helpers/pagetemp';
import Sections from '../helpers/sections';
import competitions from '../../update/competitions';

function MyOther() {

    var sections = competitions.map((comp, index) => (
        {
            title: comp.title,
            "items": [
                <div>
                    <p>{comp.about}</p>

                    {comp.pastResults && (
                        <div>
                            <h5>Past Results</h5>
                            <p>{comp.pastResults}</p>
                        </div>
                    )}

                    {comp.recentResults && Object.keys(comp.recentResults).length > 0 && (
                        <div>
                            <h5>2024 Results</h5>
                            <div className="row justify-content-center">
                                {comp.recentResults.map((result, index) => (
                                    <div key={index} className="col-12 col-sm-3 text-center my-3">
                                        <div className="circle-style">
                                            <div className={result[2].join(' ')}>
                                                {result[1]}
                                                {result[2].includes('number-style') && <sup style={{
                                                    color: 'white'
                                                }}>{getOrdinalSuffix(result[1])}</sup>}
                                            </div>

                                        </div>
                                        <span className="mt-2"><strong>{result[0]}</strong></span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            ], 
            enhance: true
        }));


    return (
        <PageTemplate id="other-comp-page">
            <h1 className="my-5">Other Competitions</h1>
            <div className="spacious-text">
                Our club has participated in many other competitions over the years. Some of our top results are listed below.
            </div>

            <Sections sections={sections} title_block={({ children }) => <h3 style={{
                textAlign: 'center',
            }} className="mt-2">{children}</h3>} />
        </PageTemplate>
    );
}

// Helper functions and styles
function getOrdinalSuffix(i) {
    const j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return 'st';
    }
    if (j === 2 && k !== 12) {
        return 'nd';
    }
    if (j === 3 && k !== 13) {
        return 'rd';
    }
    return 'th';
}

export default MyOther;
