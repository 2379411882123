import PageTemplate from "./helpers/pagetemp";
import Sections from "./helpers/sections";
import members from "../update/members";

import LeaderSection from "./helpers/LeaderSection";
import { Row, Col, Image } from "react-bootstrap";

function MyMembers() {
    var sections = [
        {
            "title": "",
            "items": [<LeaderSection key="members-leaders" />]
        }, 
        {
            "title": "Members", 
            "items": [<MemberList members={members} />]  
        }
    ];

    return (
        <PageTemplate id="members-page">
            <h1 className="my-5">Staff</h1>
            <Sections
                sections={sections}
                title_block={({ children }) => (
                    <h4 className="my-4" style={{ textAlign: 'center' }}>{children}</h4>
                )}
            />
        </PageTemplate>
    );
}

function MemberList({ members }) {
    return (
        <Row className="member-list">
            {members.map((member, index) => (
                <Col
                    xs={12}
                    sm={6}
                    md={4}
                    // lg={3}
                    key={index}
                    className="member-item"
                >
                    <span className="delimiter">★</span> {member}
                </Col>
            ))}
        </Row>
    );
}

export default MyMembers;