import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Routes, Route } from 'react-router-dom';
import { UserProvider } from './authenticate';

import Mynavbar from './components/Navbar';
import { Myhome, Mylanding } from './components/Home';
import Mymeetings from './components/Meetings';
import Myfooter from './components/Footer';
import MyARML from './components/competitions/ARML';
import MyHMMT from './components/competitions/HMMT';
import MyMembers from './components/Members';
import MyOtherCompetitions from './components/competitions/OtherCompetitions';
import MyFAQ from './components/FAQ';
import MyDocuments from './components/Documents';

function App() {
  return (
    // <UserProvider>
      <AppContent />
    // </UserProvider>
  );
}

function AppContent() {
  return (
    <div className="App">

        <div id="box">
          <div id="boxheader">
            <Mynavbar />
          </div>

          <title>Oregon Math Circle</title>

          <div id="boxcontent">
            <Routes>
              <Route path="/" element={<Mylanding />}></Route>
              <Route path="/meetings" element={<Mymeetings/>}></Route>
              <Route path="/arml" element={<MyARML />}></Route>
              <Route path="/hmmt" element={<MyHMMT />}></Route>
              <Route path="/other" element={<MyOtherCompetitions />}></Route>
              <Route path="/members" element={<MyMembers />}></Route>
              <Route path="/faq" element={<MyFAQ />}></Route>
              {/* <Route path="/docs" element={<MyDocuments />}></Route> */}
            </Routes>
          </div>

          <div id="boxfooter">
            <Routes>
              <Route path="/" element={null} />
              <Route path="*" element={<Myfooter />} />
            </Routes>
          </div>
        </div>

        <Routes>
          <Route path="/" element={<Myhome />} />
          <Route path="/*" element={null} />
        </Routes>
        <Routes>
          <Route path="/" element={<Myfooter />} />
          <Route path="/*" element={null} />
        </Routes>
    </div>
  ); 
}

export default App;
